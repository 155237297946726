import React from 'react'
import PropTypes from 'prop-types'

const TryAlly = ({ isSolutions }) => {
  if (!isSolutions) {
    return (
      <section className="try row">
        <div className="col-xs-12 col-sm-7 try__text">
          <p className="heading heading--sm hidden-mobile">Try Ally today</p>
          <p className="heading heading--sm visible-only-mobile">
            Try Ally with your team for free
          </p>
          <p className="small hidden-mobile">
            Early stage company? Eligible applicants get our Pro products for just $10
            a month. Contact us for more details.
          </p>
        </div>
        <div className="col-xs-12 col-sm-4 col-sm-offset-1">
          <a href="https://app.getally.com" className="try__btn btn btn--blue">
            Get Started
          </a>
          <ul className="try__list list list--marks">
            <li>Free 14-day trial</li>
            <li>Easy setup</li>
            <li>No card required</li>
          </ul>
        </div>
      </section>
    )
  } else {
    return (
      <section className="try try--solutions row">
        <div className="col-xs-12 hidden-mobile">
          <p className="heading heading--big">
            Try Ally with your team for free
          </p>
        </div>
        <div className="col-xs-12 hidden-mobile">
          <a
            href="https://app.getally.com"
            className="try__btn btn btn--blue btn--big"
          >
            Get Started
          </a>
        </div>
        <div className="col-xs-12 try__text visible-only-mobile">
          <p className="heading heading--sm">
            Try Ally with your team for free
          </p>
        </div>
        <div className="col-xs-12 visible-only-mobile">
          <a href="https://app.getally.com" className="try__btn btn btn--blue">
            Get Started
          </a>
          <ul className="try__list list list--marks">
            <li>Free 14-day trial</li>
            <li>Easy setup</li>
            <li>No card required</li>
          </ul>
        </div>
      </section>
    )
  }
}

TryAlly.propTypes = {
  isSolutions: PropTypes.bool
}

export default TryAlly
