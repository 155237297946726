import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'

import Try from '../sections/Try/Try'

const AboutPage = () => {
  return (
    <Layout pageName="privacy">
      <Seo title="Privacy Policy" />
      <Container>
        <section className="data">
          <p className="data__date">Effective June 20, 2019</p>
          <h1 className="heading data__heading">Ally Privacy Policy</h1>
          <p>
            This Privacy Policy is expressly incorporated into the Ally Terms of
            Service and is an essential part of the Agreement to which you are
            bound if you access or use the Ally web application,
            the www.getally.com website (“Website”) and service (together, the
            “Ally Service”). Terms used but not otherwise defined in this
            Privacy Policy have the definitions included in the Ally Terms of
            Service.
          </p>
          <p>
            This Privacy Policy describes how we collect and use the information
            you may provide or may be accessible to us when using the Ally
            Services. Please read the entire Agreement, including this Privacy
            Policy, carefully before accessing or using the Ally Service. Your
            accessing or using any part of the Ally Service evidences your
            agreement to be bound by the terms and conditions of the Agreement,
            including this Privacy Policy. If you do not agree to all the terms
            and conditions of the Agreement, including this Privacy Policy, then
            you may not access or use the Ally Service.
          </p>

          <h2 className="heading data__heading-sm">
            1. Collection of Information
          </h2>
          <p>
            The Ally Service allows you to connect various data sources such as
            Jira, Github or Google Calendar and to aggregate, analyze and
            visualize data from these data sources in a messaging platform like
            Slack, Microsoft Teams or Cisco Teams and getally.com website.
            Through your use of the Ally Service, we may have access to and/or
            collect various types of information:
          </p>

          <h2 className="heading data__heading-sm">Personal Information</h2>
          <p>
            When you use the Ally Services, you may provide us with your
            personally identifiable information either directly or through third
            party data sources, you connect to the Ally Services (“Personal
            Information”). This Personal Information may include your first and
            last name, e-mail, and credit card number when you create an account
            to log in to our network. If you provide us feedback or contact us
            via e-mail, we will collect your name and e-mail address, as well as
            any other content included in the e-mail, so that we may send you a
            reply.
          </p>

          <h2 className="heading data__heading-sm">Confidential Information</h2>
          <p>
            When you use the Ally Services, in addition to Personal Information,
            you may provide us with information that we should reasonably know
            is confidential and that you expect to be treated as confidential
            either directly or through third party data sources, you connect to
            the Ally Services (“Confidential Information”).
          </p>

          <h2 className="heading data__heading-sm">
            Information from Messaging platforms (Slack, Microsoft Teams, Cisco
            Teams)
          </h2>
          <p>
            Messages to Ally. The Ally Service requires access to your data, as
            well as the data of your team members, that is stored in or made
            available to a messaging platform, as contemplated in the Customer
            Terms of Service of a messaging platform. We have access to that
            information, which may include your Personal Information or
            Confidential Information. We also store messages directed at the
            Ally Service, whether by direct message, by conversations during the
            dialogs with Ally or an @-mention in a chat room or channel. We do
            not store messages not directed at the Ally.
          </p>

          <h2 className="heading data__heading-sm">
            Information from Third Party Data Sources
          </h2>
          <p>
            The Ally Service also requires access to your data, as well as the
            data of your team members, that is stored in or made available
            through other third party data sources in order to aggregate,
            analyze and visualize this data. We have access to that information
            when you connect the Ally Services to these data sources, which may
            include your Personal Information or Confidential Information.
          </p>

          <h2 className="heading data__heading-sm">Web-browser cookies</h2>
          <p>
            The Ally Services may use “cookies” to enhance your user experience.
            Your web browser may place cookies on their hard drive for
            record-keeping purposes and sometimes to track information about
            them. You may choose to set your web browser to reject cookies, or
            to alert you when cookies are being sent. If you do so, please note
            that some parts of the Website may not function properly. Please
            also note that “cookies” are used to collect general usage and
            volume statistical information that does not include personal
            information.
          </p>

          <h2 className="heading data__heading-sm">Web Beacons</h2>
          <p>
            Our Website may also contain electronic images known as Web beacons
            (sometimes called single-pixel gifs) that are set by our service
            providers or business partners. Web beacons are used along with
            cookies enabling us and/or our partners to compile aggregated
            statistics and analyze how our Website is used. We may use a third
            party service provider or our business partners to gather
            information about how you and others use our Website. For example,
            we will know how many users access a specific page and which links
            they clicked on. We use this aggregated information to understand
            and optimize how our Website is used.
          </p>

          <h2 className="heading data__heading-sm">Links to Other Web Sites</h2>
          <p>
            Our Website may include links to other web pages whose privacy
            practices may differ from ours. If you submit personal information
            to any of those sites, your information is governed by their privacy
            policies. We encourage you to carefully read the privacy policy of
            any website you visit.
          </p>

          <h2 className="heading data__heading-sm">Computer Information</h2>
          <p>
            Like most websites, we automatically gather information about your
            computer such as your IP address, browser type, referring/exit
            pages, and operating system.
          </p>

          <h2 className="heading data__heading-sm">2. Use of Information</h2>
          <p>
            Our primary goals in collecting information are to provide and
            improve the Ally Services, to administer your use of the Ally
            Services and to enable you to use and easily navigate the Ally
            Services. We may use your Personal Information or Confidential
            Information to let you know about products or services which may be
            of interest to you or to personalize the Ally Service to meet your
            needs or preferences. This may involve sharing certain Personal
            Information or Confidential Information, with affiliated companies
            or third parties.
          </p>
          <p>
            We may use your Personal Information or Confidential information to
            improve the Ally Services, and to deliver the Ally functionality to
            you and your team and to improve the performance of our proprietary
            prediction models.
          </p>
          <p>
            We may share your Personal Information or Confidential information
            with our service providers, business partners, subsidiaries and
            affiliates and if we do, we will require those third parties to
            protect your Personal Information and Confidential Information in
            compliance with the terms of this Privacy Policy.
          </p>
          <p>
            We do not sell, lease or transfer your Personal Information or
            Confidential information to third parties, but we may and sometimes
            shall disclose your Personal Information or Confidential information
            if any one of the following circumstances occurs:
          </p>
          <ul>
            <li>
              as required by law such as to comply with a subpoena or similar
              legal process; when we believe in good faith that disclosure is
              necessary to protect our rights, protect your safety or the safety
              of others, investigate fraud, or respond to a government request
            </li>
            <li>
              if we are involved in a merger, acquisition, or sale of all or a
              portion of our assets, you will be notified via email and/or a
              prominent notice on our Website of any change in ownership or uses
              of your personal information, as well as any choices you may have
              regarding your personal information;
            </li>
            <li>to any other third party with your prior consent to do so.</li>
          </ul>
          <p>
            We also may anonymize or aggregate Personal Information or
            Confidential Information with the Personal Information or
            Confidential information of other users (e.g. average performance
            indicators) so that the information does not identify you and does
            not identify you as a source of data. Such anonymized or aggregated
            information does not constitute Personal Information or Confidential
            Information and we reserve the right to sell, lease or transfer that
            information now or in the future.
          </p>

          <h2 className="heading data__heading-sm">3. Public Information</h2>
          <p>
            If you are a member of a team that has enabled Ally Services, we may
            publicly present on our Website (i) your team name and team avatar
            and (ii) your messaging platform username, your first and last name,
            and your messaging platform avatar. We will not publicly present
            your personal team information like reports and direct messages you
            provide to Ally.
          </p>

          <h2 className="heading data__heading-sm">4. Information Security</h2>
          <p>
            The security of your Personal Information and Confidential
            Information is important to us. We follow generally accepted
            industry-standards to protect your Personal Information, both during
            transmission and once we receive it. No method of transmission over
            the Internet, or method of electronic storage, is 100% secure,
            however. As a result, we cannot guarantee its security. Unauthorized
            entry or use, hardware or software failure, and other factors may
            compromise the security of information at any time.
          </p>
          <p>
            If you have any questions about security in using the Ally Service,
            please contact us at
            <a href="mailto:support@getally.com">support@getally.com</a>
          </p>

          <h2 className="heading data__heading-sm">
            5. Updates to Information
          </h2>
          <p>
            If you are a member of a team that has enabled Ally Services, we may
            publicly present on our Website (i) your team name and team avatar
            and (ii) your messaging platform username, your first and last name,
            and your messaging platform avatar. We will not publicly present
            your personal team information like reports and direct messages you
            provide to Ally.
          </p>
          <p>
            If you would like to review or update your Personal Information,
            please contact us at 
            <a href="mailto:support@getally.com">support@getally.com</a>
          </p>
          <p>
            If you ever decide to delete your account, you may do so by
            emailing 
            <a href="mailto:support@getally.com">support@getally.com</a>. If you
            terminate your account, any association between your account and
            information we store will no longer be accessible through your
            account. However, given the nature of sharing on the Ally Services,
            any public activity on your account prior to deletion will remain
            stored on our servers and will remain accessible to the public.
          </p>

          <h2 className="heading data__heading-sm">
            6. Changes to Privacy Policy
          </h2>
          <p>
            We may update and amend this Privacy Policy from time to time. Use
            of information we collect now is subject to the Privacy Policy in
            effect at the time such information is used. If we amend this
            Privacy Policy, we will notify you by posting an announcement on the
            Website. If we make any material changes to this Privacy Policy, we
            may also choose at our discretion to notify you through the Ally
            Services and/or by sending you an email.
          </p>
          <p>
            You are bound by any update and amendment to the Privacy Policy when
            you keep using our Website and the Ally Services after such update
            or amendment has been first posted on the Website.
          </p>
          <p>
            We encourage you to periodically review this page for the latest
            information on our privacy practices.
          </p>

          <h2 className="heading data__heading-sm">7. Contact</h2>
          <p>
            If you have any questions, comments or requests regarding the
            Privacy Policy, please contact us at 
            <a href="mailto:support@getally.com">support@getally.com</a>
          </p>
        </section>
        <Try />
      </Container>
    </Layout>
  )
}

export default AboutPage
